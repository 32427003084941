import React from 'react';
import SupplyCard from './SupplyCard';
import Line from './Line';
import { Container, Row, Col } from 'react-bootstrap';
import supplier from '../images/supplier-color.svg';
import customer from '../images/customer-color.svg';
import merchant from '../images/merchant-color.svg';

const SupplyChain = ({getTranslatedText}) => {
  return (
    <div className='supply'>
      <Container>
      <Row>
          <Col className='supply__upper-col'>
            <Line type='large' />
            <h1 className='supply__heading'>{getTranslatedText("SupplyChain Header")}</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className='supply__lower-col'>
            <SupplyCard
              image={supplier}
              title={getTranslatedText("SupplyChain Suppliers")}
              text={getTranslatedText("SupplyChain SuppliersDescp")}
            />
            
            <SupplyCard
              image={customer}
              title={getTranslatedText("SupplyChain Customers")}
              text={getTranslatedText("SupplyChain CustomersDescp")}
            />
            <SupplyCard
              image={merchant}
              title={getTranslatedText("SupplyChain Merchants")}
              text={getTranslatedText("SupplyChain MerchantsDescp")}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SupplyChain;
